<template>
	<div class="card card-custom card-stretch gutter-b">
		<div class="border-0 p-3 d-flex justify-content-center map-container" style="min-height: 60px">
			<olMap :canvasFeatures="canvasFeatures" @mapReady="getMapContext" @layerAdded="cacheVectorLayer"></olMap>
			<!-- OVERLAYS -->
			<div
				ref="markerOverlay"
				class="marker-popup"
				:style="{
					visibility: overlayVisibility,
				}"
			>
				<div class="popover-arrow"></div>
				<div
					class="marker-popup-title"
					:style="{
						background: `linear-gradient(200deg, ${focusDevice.color[0]}, ${focusDevice.color[1]})`,
					}"
				>
					<h5>{{ focusDevice.name }}</h5>
				</div>
				<div class="marker-popup-body">
					<div>
						<span>GPS: </span><span class="marker-stat">{{ focusDevice.lat }}, {{ focusDevice.lon }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { fromLonLat } from 'ol/proj';
import { Icon, Style } from 'ol/style';
import Overlay from 'ol/Overlay';

const overlayColors = {
	OK: ['#c4fce5', '#37c583'],
	ALARM: ['#f7ccd0', '#ED6B75'],
	DEACTIVATED: ['#e2eeff', '#bac3d1'],
	SCPC: ['#bdd9fc', '#659BE0'],
	default: ['#e2eeff', '#bac3d1'],
};

const setCanvasMarkers = (properties, mnc = null) => {
	let features = [];
	if (!properties && !mnc) {
		return;
	}
	if (properties) {
		if (properties.remote) {
			if (properties.remote.lon && properties.remote.lat) {
				features.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: fromLonLat([properties.remote.lon, properties.remote.lat]),
					},
					properties: {
						name: properties.remote.Name,
						id: 'remote',
						status: 'SCPC',
						type: 'marker',
					},
				});
			}
		}
		if (properties.hub) {
			if (properties.hub.lon && properties.hub.lat) {
				features.push({
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: fromLonLat([properties.hub.lon, properties.hub.lat]),
					},
					properties: {
						name: properties.hub.Name,
						id: 'hub',
						status: 'SCPC',
						type: 'marker',
					},
				});
			}
		}
	}
	if (mnc) {
		features.push({
			type: 'Feature',
			geometry: {
				type: 'Point',
				coordinates: fromLonLat([mnc.lon, mnc.lat]),
			},
			properties: {
				name: mnc.name,
				id: 'mnc',
				status: 'SCPC',
				type: 'marker',
			},
		});
	}

	return {
		name: 'markers',
		geojson: {
			type: 'FeatureCollection',
			features: features,
		},
		style: feature => {
			let srcs = {
				OK: '/media/markers/marker_green.png',
				ALARM: '/media/markers/marker.png',
				SCPC: '/media/markers/marker_blue.png',
				DEACTIVATED: '/media/markers/marker_white.png',
				BEAMCHANGE: '/media/svg/Custom/beamswitch.svg',
				default: '/media/markers/marker_grey.png',
			};
			let status = feature.getProperties().status;
			return new Style({
				image: new Icon({
					anchor: [0.5, 1],
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					src: srcs?.[status] || srcs['default'],
				}),
			});
		},
	};
};

const setSatelliteMarkers = satlon => {
	if (satlon > 180) satlon -= 360;
	return {
		name: 'satellites',
		geojson: {
			type: 'FeatureCollection',
			features: [
				{
					type: 'Feature',
					geometry: {
						type: 'Point',
						coordinates: fromLonLat([satlon, 0]),
					},
					properties: {
						type: 'satellite',
					},
				},
			],
		},
		style: new Style({
			image: new Icon({
				anchorXUnits: 'fraction',
				anchorYUnits: 'fraction',
				scale: 0.2,
				src: '/media/svg/custom/sat-white.svg',
				color: 'black',
			}),
		}),
	};
};

export default {
	name: 'SiteComtechMap',
	props: ['properties', 'widgetproperties'],
	components: {
		olMap: () => import('@/view/content/lib/ol.vue'),
	},
	data() {
		return {
			map: null,
			canvasFeatures: [],
			vLayers: {},
			markerOverlay: null,
			hovered: [],
			overlayVisibility: 'hidden',
			mncpc: false,
			mnc: {},
			focusDevice: {
				name: null,
				lat: null,
				lon: null,
				color: overlayColors['SCPC'],
			},
		};
	},
	methods: {
		getMapContext(map) {
			this.map = map;

			if (this.properties.remote && this.properties.remote.lat) {
				this.map.getView().setCenter(fromLonLat([this.properties.remote?.lon, this.properties.remote?.lat]));
			}
			if ((!this.properties.remote || !this.properties.remote.lat) && this.mncpc) {
				this.map.getView().setCenter(fromLonLat([this.mnc.lon, this.mnc.lat]));
			}

			this.markerOverlay = new Overlay({
				element: this.$refs.markerOverlay,
				positioning: 'center-center',
				stopEvent: false,
				offset: [12, -this.$refs.markerOverlay.clientHeight / 2],
			});
			this.overlayVisibility = 'visible';
			this.map.addOverlay(this.markerOverlay);
			this.map.on('pointermove', event => this.handlePointermove(event));
		},
		cacheVectorLayer(layer) {
			this.vLayers = { ...layer, ...this.vLayers };
		},
		handlePointermove(event) {
			this.markerOverlay.setPosition(undefined);
			this.map.forEachFeatureAtPixel(event.pixel, feature => {
				switch (feature.get('type')) {
					case 'marker':
						this.setFocusDevice(feature.get('id'));
						this.markerOverlay.setPosition(feature.getGeometry().getCoordinates());
						return true;
				}
			});
		},
		setFocusDevice(id) {
			if (id == 'mnc' && this.mnc) {
				let deviceInfo = this.mnc;
				this.focusDevice.name = deviceInfo.name;
				this.focusDevice.lat = deviceInfo.lat;
				this.focusDevice.lon = deviceInfo.lon;
				this.focusDevice.color = overlayColors['SCPC'];
			} else {
				if (this.properties[id].lat) {
					let deviceInfo = this.properties[id];
					this.focusDevice.name = deviceInfo.name;
					this.focusDevice.lat = deviceInfo.lat;
					this.focusDevice.lon = deviceInfo.lon;
					this.focusDevice.color = overlayColors['SCPC'];
				}
			}
		},
	},
	created() {
		if (this.widgetproperties && this.widgetproperties.length>0) {
			if (this.widgetproperties.mncpc) {
				this.$http.get('swmncpc/' + this.widgetproperties.mncpc).then(res => {
					if (res.data.data) {
						this.mnc = res.data.data;
						if (this.widgetproperties.mncpclabel) {
							this.mnc.name = this.widgetproperties.mncpclabel;
						}
						this.mncpc = true;

						this.canvasFeatures = [
							setCanvasMarkers(this.properties, this.mnc),
							this.properties && this.properties.hub && this.properties.hub.satlon ? setSatelliteMarkers(this.properties.hub.satlon) : [],
						];
						this.setFocusDevice('mnc');
						if (this.map) {
							this.map.getView().setCenter(fromLonLat([this.mnc.lon, this.mnc.lat]));
						}
					}
				});
			}
		} else {
			this.canvasFeatures = [setCanvasMarkers(this.properties), setSatelliteMarkers(this.properties.hub.satlon)];
			this.setFocusDevice('remote');
		}
	},
};
</script>

<style>
.map-container {
	position: relative;
	min-height: 200px;
	transition: all 300ms ease-in-out;
}
.satOverlay {
	height: 35px;
	position: absolute;
	border-radius: 5px;
	background: white;
}
.sat-name {
	font-weight: 500;
	white-space: nowrap;
}
.toolbar {
	position: absolute;
	z-index: 1;
	top: 0;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
}
.toolbar-icon i {
	color: grey;
}
.sat-icon svg {
	fill: grey;
}
.btn-active {
	background: #ffa800;
	border-color: #ffa800;
}
.btn-active.toolbar-icon i {
	color: white;
}
.btn-active.sat-icon svg,
.sat-icon:hover svg {
	fill: white;
}
#networks-legend {
	min-height: 35px;
}
.network {
	border: 1px solid #fff;
	cursor: pointer;
}
.network:hover {
	border: 1px solid rgba(0, 153, 255, 0.2);
	border-radius: 3px;
	background: rgba(0, 153, 255, 0.1);
}
.network-color {
	display: inline-block;
	height: 20px;
	width: 20px;
	border-radius: 20px;
}
.marker-popup,
.beamchange-popup {
	position: absolute;
	background: white;
	width: 250px;
	height: 100px;
	border-radius: 5px;
	box-shadow: 2px 4px 13px -5px rgb(99, 123, 124);
}
.beamchange-popup {
	min-height: 80px;
	box-shadow: 2px 4px 13px -5px #f1c670;
}
.popover-arrow {
	left: 0;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 14px;
	width: 14px;
}
.sat-arrow {
	left: -1px;
	position: absolute;
	top: 50%;
	background-color: #fff;
	transform: translate(-50%, -50%) rotate(45deg);
	height: 12px;
	width: 12px;
}
.marker-popup-title {
	padding: 10px 10px;
	border-radius: 5px 5px 0 0;
}
.beamchange-popup-title {
	background: linear-gradient(#f8bb43, #f1c670);
	padding: 5px 10px;
}
.marker-popup-title h5 {
	margin: 0;
}
.marker-popup-body {
	min-height: 80px;
	padding: 10px;
}
.marker-stat {
	font-weight: 700;
}
.scroll-note {
	position: absolute;
	color: #777;
	right: 15px;
}
</style>
